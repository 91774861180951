<template>
  <div>
    <pdf-view :pdfUrl="pdfUrl"></pdf-view>
    <myAudio ref="audio" :list="audioList" :goods_course_id="goods_course_id"></myAudio>
    <!-- 未学习 -1  学习中 0 已学完 1 ？？这样 -->
    <p class="title" v-if="baseInfo.category_id!=226">理论课程</p>
    <div class="lesson-item" v-for="(item, index) of list1" :key="item.id" :class="{lessonShow: item.show}">
      <div
        class="title1"
        v-if="item.lesson && item.lesson.length"
        @click="changeShow(index, 1)"
      >
        <p>{{ item.name }}</p>
        <button @click.stop="toViewImg(item)" v-if="item.map.length">
          思维导图
        </button>
        <i
          :class="{
            'el-icon-arrow-down': !item.show,
            'el-icon-arrow-up': item.show,
          }"
        ></i>
      </div>
      <div class="title1" v-else @click="toPlay(1, item)">
        <p>
          {{ item.name }}
          <template v-if="baseInfo.category_id!=226">
            <span class="blue" v-if="item.study_status == 0">学习中</span>
            <span class="red" v-if="item.study_status == -1">未学习</span>
            <span class="green" v-if="item.study_status == 1">已学完</span>
            <span class="green" v-if="item.test_status == 1">随堂练习已通过</span>
            <span class="red" v-if="item.test_status == 2">随堂练习未通过</span>
          </template>
          
        </p>
        <button @click.stop="toViewImg(item)" v-if="item.map.length">
          思维导图
        </button>
        <img :src="playImg" />
      </div>
      <div
        class="lesson-detail"
        v-if="item.show && item.lesson && item.lesson.length"
      >
        <div v-for="(v, i) in item.lesson" :key="i" :class="{lessonShow: v.show}">
          <div class="title2" v-if="v.speak && v.speak.length">
            <p>{{ v.name }}</p>
            <button @click.stop="toViewImg(v)" v-if="v.map.length">
              思维导图
            </button>
          </div>
          <div class="title2" v-else @click="toPlay(2, item, v)">
            <p>
              {{ v.name }}
              <template v-if="baseInfo.category_id!=226">
                <span class="blue" v-if="v.study_status == 0">学习中</span>
                <span class="red" v-if="v.study_status == -1">未学习</span>
                <span class="green" v-if="v.study_status == 1">已学完</span>
                <span class="green" v-if="v.test_status == 1">随堂练习已通过</span>
                <span class="red" v-if="v.test_status == 2">随堂练习未通过</span>
              </template>
              
            </p>
            <img :src="playImg" />
          </div>
          <div class="list3" v-if="v.speak && v.speak.length">
            <div
              class="title2"
              :class="{lessonShow: vv.show}"
              v-for="(vv, ii) in v.speak"
              :key="ii"
              @click="toPlay(3, item, v, vv)"
            >
              <p>
                {{ vv.name }}
                <template v-if="baseInfo.category_id!=226">
                  <span class="blue" v-if="vv.study_status == 0">学习中</span>
                  <span class="red" v-if="vv.study_status == -1">未学习</span>
                  <span class="green" v-if="vv.study_status == 1">已学完</span>
                  <span class="green" v-if="vv.test_status == 1">随堂练习已通过</span>
                  <span class="red" v-if="vv.test_status == 2">随堂练习未通过</span>
                </template>
                
              </p>
              <img :src="playImg" />
            </div>
          </div>
          <detail-examin :list="v.speak" :testInfo="v.test" v-if="v.test.id && category_id!=225"></detail-examin>
        </div>
      </div>
      <detail-examin :list="item.lesson" :testInfo="item.test" v-if="item.test.id && (!( item.lesson && item.lesson.length) || item.show)  && category_id!=225" style="margin-bottom: 10px"></detail-examin>
    </div>
    <p class="title" v-if="list2.length">实操课程</p>
    <div class="lesson-item" v-for="(item, index) of list2" :key="item.id">
      <div
        class="title1"
        v-if="item.lesson && item.lesson.length"
        @click="changeShow(index, 2)"
      >
        <p>{{ item.name }}</p>
        <button @click.stop="toViewImg(item)" v-if="item.map.length">
          思维导图
        </button>
        <i
          :class="{
            'el-icon-arrow-down': !item.show,
            'el-icon-arrow-up': item.show,
          }"
        ></i>
      </div>
      <div class="title1" v-else @click="toPlay2(1, item)">
        <p>
          {{ item.name }}
          <template v-if="baseInfo.category_id!=226">
            <span class="blue" v-if="item.study_status == 0">学习中</span>
            <span class="red" v-if="item.study_status == -1">未学习</span>
            <span class="green" v-if="item.study_status == 1">已学完</span>
            <span class="green" v-if="item.test_status == 1">随堂练习已通过</span>
            <span class="red" v-if="item.test_status == 2">随堂练习未通过</span>
          </template>
          
        </p>
        <button @click.stop="toViewImg(item)" v-if="item.map.length">
          思维导图
        </button>
        <img :src="playImg" />
      </div>
      <div
        class="lesson-detail"
        v-if="item.show && item.lesson && item.lesson.length"
      >
        <div v-for="(v, i) in item.lesson" :key="i">
          <div class="title2" v-if="v.speak && v.speak.length">
            <p>{{ v.name }}</p>
            <button @click.stop="toViewImg(v)" v-if="v.map.length">
              思维导图
            </button>
          </div>
          <div class="title2" v-else @click="toPlay2(2, item, v)">
            <p>
              {{ v.name }}
              <template v-if="baseInfo.category_id!=226">
                <span class="blue" v-if="v.study_status == 0">学习中</span>
                <span class="red" v-if="v.study_status == -1">未学习</span>
                <span class="green" v-if="v.study_status == 1">已学完</span>
                <span class="green" v-if="v.test_status == 1">随堂练习已通过</span>
                <span class="red" v-if="v.test_status == 2">随堂练习未通过</span>
              </template>
              
            </p>
            <img :src="playImg" />
          </div>
          <div class="list3" v-if="v.speak && v.speak.length">
            <div
              class="title2"
              v-for="(vv, ii) in v.speak"
              :key="ii"
              @click="toPlay2(3, item, v, vv)"
            >
              <p>
                {{ vv.name }}
                <template v-if="baseInfo.category_id!=226">
                  <span class="blue" v-if="vv.study_status == 0">学习中</span>
                  <span class="red" v-if="vv.study_status == -1">未学习</span>
                  <span class="green" v-if="vv.study_status == 1">已学完</span>
                  <span class="green" v-if="vv.test_status == 1">随堂练习已通过</span>
                  <span class="red" v-if="vv.test_status == 2">随堂练习未通过</span>
                </template>
                
              </p>
              <img :src="playImg" />
            </div>
          </div>
        </div>
      </div>
      <!-- <detail-video :list="item.lesson" :testInfo="{
        time:item.video_time,
        name:item.name,
        status_name:item.video_status,
        video_url:item.video_url,
        id:item.id,
        discipline_id:item.discipline_id,
        study_status: item.study_status
      }" v-if="!( item.lesson && item.lesson.length) || item.show" style="margin-bottom: 10px"></detail-video> -->
    </div>
    <el-dialog title="思维导图" :visible.sync="dialogTableVisible">
      <div style="height: 480px; overflow: auto">
        <img
          :src="item.cover"
          v-for="(item, index) of imgList"
          :key="index"
          alt=""
        />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import detailExamin from './detailExamin.vue'
import detailVideo from './detailVideo.vue'
import myAudio from "@/components/Audio.vue"
import pdfView from '@/components/pdfView.vue'
export default {
  components:{detailExamin, detailVideo,myAudio,pdfView},

  props: ["list",'down_list', "name", "isBuy","goods_course_id","price","category_id","baseInfo"],
  data() {
    return {
      closeIcon: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E5%85%B3%E9%97%AD26%402x.png',
      pdfUrl: '',
      dialogTableVisible: false,
      imgList: [],
      height: 0,
      top: 0,
      list1: [],
      audioList1:[],
      list2: [],
      audioList2:[],
      audioList:[],
      playImg:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/play.jpg",
    };
  },
  watch: {
    list() {
      let myList = this.list.map((item) => {
        item.show = item.show || false;
        return item;
      });
      this.list1 = myList
      this.audioList1 = this.flatArr(this.list, []).filter(item=>{
        return item.video_ids && item.video_ids[0].type==3
      })
    },
    down_list(){
      let myList = this.down_list.map((item) => {
        item.show = false;
        return item;
      });
      this.list2 = myList
      this.audioList2 = this.flatArr(this.down_list, []).filter(item=>item.video_ids && item.video_ids[0].type==3)
    }
  },
  computed: {
    myUser(){
      return this.$store.state.user;
    },
    vipInfo() {
      return this.$store.state.vipInfo;
    },
    platName() {
      return this.$store.state.plat.name;
    },
  },
  mounted() {},
  methods: {
    changeShow(index, type) {
      this["list" + type][index].show = !this["list" + type][index].show;
      this.$set(this["list" + type], index, this["list" + type][index]);
    },
    toPlay(type, item, v, vv) {
      
       window.sessionStorage.setItem(this.$route.query.id, true)
       console.log(this.price)
      if (this.isBuy == 0 && this.price && this.myUser.mobile!='18666666666') {
        this.$toast(this.$route.params.plat_type !=='tuoyu' ? "请购买后开始学习" : "请扫码联系客服开通课程");
        return;
      }
      if(this.baseInfo && this.baseInfo.playmode_id == 17){//pdf资源
        if(type == 1){
          this.pdfUrl = item.pdf_url
        }
        if(type == 2){
          this.pdfUrl = v.pdf_url
        }
        if(type == 3){
          this.pdfUrl = vv.pdf_url
        }
        if(!this.pdfUrl){
          this.$toast("当前章节没有资料");
        }
        return
      }
      if (type == 1 && !item.video_ids && !item.pdf_ans && !item.pdf_ppt && !item.pdf_examine 
        || type == 2 && !v.video_ids && !v.pdf_ans && !v.pdf_ppt && !v.pdf_examine 
        || type == 3 && !vv.video_ids && !vv.pdf_ans && !vv.pdf_ppt && !vv.pdf_examine  
        ) {
        this.$toast("当前章节没有资料");
        return;
      }
      let url = "./player2";
      let isAudio = 0
     
      if (type == 1) {
        url = url + "?chapter_id=" + item.id;
        if(item.video_ids && item.video_ids[0].type==3){//音频
          isAudio = item.id
        }
      } else if (type == 2) {
        url = url + "?chapter_id=" + item.id + "&lesson_id=" + v.id;
        if(v.video_ids && v.video_ids[0].type==3){//音频
          isAudio = v.id
        }
      } else if (type == 3) {
        url =
          url +
          "?chapter_id=" +
          item.id +
          "&lesson_id=" +
          v.id +
          "&speak_id=" +
          vv.id;
          if(vv.video_ids && vv.video_ids[0].type==3){//音频
            isAudio = vv.id
          }
      }
      url = url + "&id=" + this.$route.query.id;
      console.log(isAudio)
      if(isAudio){
        let audioIndex = this.audioList1.findIndex(audioItem=>audioItem.id == isAudio)
        this.audioList = this.audioList1
        console.log(this.audioList)
        this.$refs.audio.getData(this.audioList1,audioIndex)
      }else{
        this.$router.push(url);
      }
    },
    toPlay2(type, item, v, vv) {
      
      window.sessionStorage.setItem(this.$route.query.id, true)
      console.log(this.isBuy)
      console.log(this.price)
      if (this.isBuy == 0 && this.price) {
        this.$toast(this.$route.params.plat_type !=='tuoyu' ? "请购买后开始学习" : "请扫码联系客服开通课程");
        return;
      }
      if(this.baseInfo && this.baseInfo.playmode_id == 17){//pdf资源
        if(type == 1){
          this.pdfUrl = item.pdf_url
        }
        if(type == 2){
          this.pdfUrl = v.pdf_url
        }
        if(type == 3){
          this.pdfUrl = vv.pdf_url
        }
        if(!this.pdfUrl){
          this.$toast("当前章节没有资料");
        }
        return
      }
      if (type == 1 && !item.video_ids || type == 2 && !v.video_ids || type == 3 && !vv.video_ids ) {
        this.$toast("当前章节没有音视频资料");
        return;
      }
      let isAudio = 0
      let url = "./player2?lessonType=down";
      if (type == 1) {
        if(item.video_ids[0].type==3){//音频
          isAudio = item.id
        }
        url = url + "&chapter_id=" + item.id;
      } else if (type == 2) {
        if(v.video_ids[0].type==3){//音频
          isAudio = v.id
        }
        url = url + "&chapter_id=" + item.id + "&lesson_id=" + v.id;
      } else if (type == 3) {
        if(vv.video_ids[0].type==3){//音频
          isAudio = vv.id
        }
        url =
          url +
          "&chapter_id=" +
          item.id +
          "&lesson_id=" +
          v.id +
          "&speak_id=" +
          vv.id;
      }
      url = url + "&id=" + this.$route.query.id;
      if(isAudio){
        let audioIndex = this.audioList2.findIndex(audioItem=>audioItem.id == isAudio)
        this.$refs.audio.getData(this.audioList2,audioIndex)
      }else{
        this.$router.push(url);
      }
    },
    toViewImg(v) {
      if (v.map.length) {
        this.imgList = v.map;
        this.dialogTableVisible = true;
      }
    },
    flatArr(arr, idArr) {
      let myArr = [];

      arr.forEach((item) => {
        if (item.lesson && item.lesson.length) {
          // console.log("lesson");
          let arr = [
            { label: "lessonId", value: item.id },
            { label: "myType", value: "chapter" },
          ];
          // console.log(idArr);
          myArr.push(...this.flatArr(item.lesson, [...idArr, ...arr]));
        } else if (item.speak && item.speak.length) {
          // console.log("speak");
          let arr = [
            { label: "chapterId", value: item.id },
            { label: "myType", value: "speak" },
          ];
          myArr.push(...this.flatArr(item.speak, [...idArr, ...arr]));
        } else {
          // console.log("none");
          idArr.forEach((v) => {
            item[v.label] = v.value;
          });
          myArr.push(item);
        }
      });
      return myArr;
    },
  },
};
</script>

<style scoped lang="scss">

.title {
  font-size: 16px;
  padding-top: 30px;
  padding-bottom: 8px;
}
.blue,
.red,
.green {
  display: inline-block;
  margin-left: 10px;
  height: 18px;
  line-height: 18px;
  padding: 0 6px;
  border-radius: 3px;
  font-size: 12px;
}
.blue {
  background: #cee7f8;
  color: #0080d6;
}
.red {
  background: #f9dbd6;
  color: #d86855;
}
.green {
  color: #0e9c58;
  background: #caefde;
}
.lesson-item {
  background: #f9f9f9;
  cursor: pointer;
  margin-bottom: 10px;
  overflow: hidden;
  .title1 {
    display: flex;
    align-items: center;
    height: 53px;
    padding-left: 10px;
    padding-right: 20px;
    p {
      flex: 1;
      font-weight: bold;
    }
    > button {
      width: 80px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      background: #0080d6;
      border-radius: 4px;
      border: 0;
      color: #fff;
      font-size: 14px;
      margin-right: 30px;
    }
    i {
      color: #666666;
    }
  }
  .lesson-detail {
    border-top: 1px solid #f0f0f0;
    .title2 {
      display: flex;
      align-items: center;
      height: 53px;
      padding-left: 31px;
      padding-right: 20px;
      font-size: 14px;
      color: #666666;
      p {
        flex: 1;
      }
      > button {
        width: 80px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        background: #0080d6;
        border-radius: 4px;
        border: 0;
        color: #fff;
        font-size: 14px;
        margin-right: 30px;
      }
      > span {
        color: #999;
      }
    }
    .list3 {
      .title2 {
        border-top: 1px solid #f0f0f0;
        padding-left: 49px;
      }
    }
  }
  .free {
    width: 40px;
    height: 28px;
    background: #ff5d5e;
    border-radius: 5px;
    line-height: 28px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .play-btn {
    width: 27px;
    height: 27px;
    background: url("https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E7%BC%96%E7%BB%84%202%E5%A4%87%E4%BB%BD%202%402x.png")
      no-repeat center / contain;
  }

  .lesson-audio {
    flex: 1;
    margin: auto 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    height: 60px;
  }

  .lesson-audio .lesson-title {
    line-height: 22px;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    position: relative;
    font-size: 16px;
    display: -webkit-box;
    overflow: hidden;
    margin-bottom: 5px;
  }

  .lesson-msg {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 26px;
    color: #666;
    width: 100%;
  }

  .lesson-msg .lesson-time,
  .lesson-msg .lesson-count,
  .lesson-msg .lesson-status {
    display: flex;
    align-items: center;
    color: #666;
    margin-right: 20px;
    white-space: nowrap;
  }

  .lesson-msg img {
    height: 14px;
    margin-right: 5px;
  }

  .lesson-video {
    position: relative;
    float: left;
    margin-right: 10px;
  }

  .lesson-video img {
    display: block;
    width: 108px;
    height: 60px;
    border-radius: 5px;
  }

  .lesson-video .video-duration {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 10px;
    font-weight: 500;
    color: #fff;
    height: 32px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 0 3px 3px 0;
  }

  .lesson-video::after {
    display: inline-block;
    position: absolute;
    top: 15px;
    left: 40px;
    content: "";
    width: 30px;
    height: 30px;
    background: url("https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/MTU4NDI2MDIzODA1.png")
      no-repeat center / contain;
  }
}
</style>